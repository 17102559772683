import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {OrganizationService} from "../../core/services/organization.service";
import {HttpClient} from "@angular/common/http";
import {
  EditLsTirolAlarmGroupDialogData
} from "../edit-ls-tirol-alarm-group-dialog/edit-ls-tirol-alarm-group-dialog.component";
import {Observable} from "rxjs";
import {Competence} from "../../core/network/models/Competence";
import {ApiService} from "../../core/services/api.service";
import {OrganizationCompetencesPutRequest} from "../../core/network/request/OrganizationCompetencesPutRequest";
import {OrganizationCompetencesPostRequest} from "../../core/network/request/OrganizationCompetencesPostRequest";

@Component({
  selector: 'app-create-edit-competence-dialog',
  templateUrl: './create-edit-competence-dialog.component.html',
  styleUrls: ['./create-edit-competence-dialog.component.scss']
})
export class CreateEditCompetenceDialogComponent implements OnInit {

  isLoading = false;
  isSaving = false;

  selectedOrganizationId: number | null = null;

  competence: Competence | null = null;

  isCreate = false;

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<CreateEditCompetenceDialogComponent>,
              private organizationService: OrganizationService, private httpClient: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: EditLsTirolAlarmGroupDialogData | null, private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.selectedOrganizationId = this.organizationService.selectedOrganizationId;

    this.isCreate = !this.data?.id;

    if (this.isCreate) {
      this.competence = {
        id: '',
        name: '',
        shortName: null,
        description: null,
        color: '#000000',
        priority: null
      } as Competence;
    } else {
      this.loadCompetence();
    }
  }

  onCloseClick(event: any): void {
    this.close();
  }

  onSaveClick(event: MouseEvent): void {
    /*let organizationGroupId: string | null = this.organizationGroupId;
    if (organizationGroupId == "") organizationGroupId = null;

    if (this.userSelectionTypeCode == "group" && !organizationGroupId) {
      alert("Keine Gruppe ausgewählt! Bitte wähle eine Gruppe und versuche es nochmal.");
      return;
    }

    let pushTypeCode: string | null = this.pushTypeCode;
    if (pushTypeCode == "") pushTypeCode = "none";

    this.isSaving = true;

    let request = {
      userSelectionTypeCode: this.userSelectionTypeCode,
      organizationGroupId: organizationGroupId,
      pushTypeCode: pushTypeCode
    } as BindingsLsTirolAlarmGroupsIdRequest;

    Api.postBindingsLsTirolAlarmGroupsId(this.httpClient, `${this.selectedOrganizationId}`, this.data!.id, request, () => {
      this.dialogRef.close();
    }, error => {
      // todo
    }, () => {
      this.isSaving = false;
    })*/

    if (!this.competence)
      return;

    if (this.competence.name.trim() == "") {
      alert("Ungültige Eingabe! Name darf nicht leer sein.");
      return;
    }


    this.isSaving = true;

    if (this.isCreate) {
      this.apiService.organizationCompetencePost(`${this.selectedOrganizationId}`, {
        name: this.competence.name,
        shortName: this.competence.shortName,
        description: this.competence.description,
        color: this.competence.color,
        priority: this.competence.priority
      } as OrganizationCompetencesPostRequest, () => {
        this.close();
      }, error => {
        alert("Fehler beim Erstellen!");
      }, () => {
        this.isSaving = false;
      });
    } else {
      this.apiService.organizationCompetencePut(`${this.selectedOrganizationId}`, this.competence!.id, {
        name: this.competence.name,
        shortName: this.competence.shortName,
        description: this.competence.description,
        color: this.competence.color,
        priority: this.competence.priority
      } as OrganizationCompetencesPutRequest, () => {
        this.close();
      }, error => {
        alert("Fehler beim Speichern!");
      }, () => {
        this.isSaving = false;
      });
    }
  }

  private loadCompetence(): void {
    this.isLoading = true;

    const organizationId = this.organizationService.getSelectedOrganization()?.id ?? 0;
    this.apiService.organizationCompetence(organizationId.toString(), this.data!.id, response => {
      this.competence = response.competence;
      this.isLoading = false;
    }, error => {
      // todo: show error
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  public static open(dialog: MatDialog, data: CreateEditCompetenceDialogData): Observable<any> {
    return dialog.open(CreateEditCompetenceDialogComponent, {
      maxWidth: 600,
      panelClass: 'sp-dialog-panel',
      disableClose: false,
      data: data
    }).afterClosed()
  }

  private close(): void {
    this.dialogRef.close();
  }
}


export interface CreateEditCompetenceDialogData {
  id: string | null;
}
