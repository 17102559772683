import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {OrganizationService} from "../../../../core/services/organization.service";
import {ApiService} from "../../../../core/services/api.service";
import {Competence} from "../../../../core/network/models/Competence";
import {
  CreateEditCompetenceDialogComponent,
  CreateEditCompetenceDialogData
} from "../../../../dialogs/create-edit-competence-dialog/create-edit-competence-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-competences',
  templateUrl: './competences.component.html',
  styleUrls: ['./competences.component.scss']
})
export class CompetencesComponent implements OnInit, OnDestroy {

  isLoading = false;

  competences: Competence[] = [];

  isUserManager = false;

  constructor(private toolbarService: ToolbarService, private organizationService: OrganizationService,
              private apiService: ApiService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.isUserManager = this.organizationService.getSelectedOrganization()?.is_user_manager ?? false;

    this.toolbarService.setHierarchy([{title: "Mitglieder", path: "/home/members"}]);
    this.toolbarService.setPageTitle("Kompetenzen");

    this.loadData();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
    this.toolbarService.resetActions();
  }

  private loadData() {
    this.isLoading = true;

    const organizationId = this.organizationService.getSelectedOrganization()?.id ?? 0;
    this.apiService.organizationCompetences(organizationId.toString(), response => {
      this.competences = response.competences.sort((a, b) => a.name > b.name ? 1 : -1);
      this.isLoading = false;

      // todo: remove
      //this.onCompetenceClick({} as MouseEvent, this.competences[0]);
    }, error => {
      // todo: show error
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  onCreateCompetenceClick(event: MouseEvent) {
    CreateEditCompetenceDialogComponent
      .open(this.dialog, {} as CreateEditCompetenceDialogData)
      .subscribe(() => {
        this.loadData();
      });
  }

  onCompetenceClick(event: MouseEvent, competence: Competence): void {
    CreateEditCompetenceDialogComponent.open(this.dialog, {
      id: competence.id
    } as CreateEditCompetenceDialogData).subscribe(() => {
      this.loadData();
    });
  }

  /**
   * https://dev.to/louis7/how-to-choose-the-font-color-based-on-the-background-color-402a
   * @param backgroundColor
   */
  textColorBasedOnBackground(backgroundColor: string): string {
    backgroundColor = backgroundColor.substring(1);
    const r = parseInt(backgroundColor.substring(0, 2), 16); // 0 ~ 255
    const g = parseInt(backgroundColor.substring(2, 4), 16);
    const b = parseInt(backgroundColor.substring(4, 6), 16);

    const srgb = [r / 255, g / 255, b / 255];
    const x = srgb.map((i) => {
      if (i <= 0.04045) {
        return i / 12.92;
      } else {
        return Math.pow((i + 0.055) / 1.055, 2.4);
      }
    });

    const L = 0.2126 * x[0] + 0.7152 * x[1] + 0.0722 * x[2];
    return L > 0.179 ? "#000" : "#fff";
  }
}
