<main class="sp-dialog">
  <header>
    <div class="header-icon">
      <mat-icon>lightbulb</mat-icon>
    </div>
    <div class="header-title">Kompetenz {{ isCreate ? 'erstellen' : 'bearbeiten' }} (Beta)</div>
    <div class="header-actions">
      <mat-icon matRipple (click)="onCloseClick($event)">close</mat-icon>
    </div>
  </header>
  <div class="content">
    <div class="sp-dialog-loading" *ngIf="isLoading">
      <mat-spinner diameter="32" strokeWidth="3"></mat-spinner>
    </div>

    <ng-container *ngIf="competence">
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Name</mat-label>
        <input matInput type="text" placeholder="" [(ngModel)]="competence.name" [disabled]="isLoading" required>
      </mat-form-field>

      <mat-form-field appearance="fill" color="accent">
        <mat-label>Kurzbezeichnung</mat-label>
        <input matInput type="text" placeholder="" [(ngModel)]="competence.shortName" [disabled]="isLoading">
      </mat-form-field>

      <mat-form-field appearance="fill" color="accent">
        <mat-label>Beschreibung</mat-label>
        <input matInput type="text" placeholder="" [(ngModel)]="competence.description" [disabled]="isLoading">
      </mat-form-field>

      <mat-form-field appearance="fill" color="accent">
        <mat-label>Priorität</mat-label>
        <input matInput type="number" placeholder="" [(ngModel)]="competence.priority" [disabled]="isLoading">
        <mat-hint>Feld leer lassen, um Kompetenz am Monitor auszublenden.</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Farbe</mat-label>
        <mat-select [(ngModel)]="competence.color">
          <mat-option [value]="'#ffffff'">Weiß</mat-option>
          <mat-option [value]="'#000000'">Schwarz</mat-option>
          <mat-option [value]="'#ff0000'">Rot</mat-option>
          <mat-option [value]="'#0fff00'">Grün</mat-option>
          <mat-option [value]="'#0000ff'">Blau</mat-option>
          <mat-option [value]="'#fff000'">Gelb</mat-option>
        </mat-select>
      </mat-form-field>

      <!--<mat-form-field appearance="fill" color="accent">
        <mat-label>Farbe</mat-label>
        <input matInput type="text" placeholder="" [(ngModel)]="competence.color" [disabled]="isLoading">
      </mat-form-field>-->
    </ng-container>
    <footer>
      <button class="btn-action btn-primary" (click)="onSaveClick($event)"
              [disabled]="isLoading">{{ isCreate ? 'Erstellen' : 'Speichern' }}
      </button>
    </footer>
  </div>
</main>
