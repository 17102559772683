import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToolbarService} from "../../../core/services/toolbar.service";
import {OrganizationService} from "../../../core/services/organization.service";
import {ApiService} from "../../../core/services/api.service";
import {Member} from "../../../core/network/models/Member";

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit, OnDestroy {

  isLoading = false;

  members: Member[] = [];

  isUserManager = false;
  isAdministrator = false;

  showCompetencesBtn = false;

  constructor(private toolbarService: ToolbarService, private organizationService: OrganizationService, private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.isUserManager = this.organizationService.getSelectedOrganization()?.is_user_manager ?? false;
    this.isAdministrator = this.organizationService.getSelectedOrganization()?.is_administrator ?? false;

    let organizationId = this.organizationService.getSelectedOrganization()?.id;
    this.showCompetencesBtn = organizationId == 1 || organizationId == 1949 || organizationId == 2253;

    this.loadData();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetActions();
  }

  private loadData() {
    this.isLoading = true;

    const organizationId = this.organizationService.getSelectedOrganization()?.id ?? 0;
    this.apiService.memberList({organization_id: organizationId.toString()}, response => {
      this.members = response.members.sort((a: Member, b: Member) => {
        const nameA: string = `${a.first_name} ${a.last_name}`.toUpperCase();
        const nameB: string = `${b.first_name} ${b.last_name}`.toUpperCase();

        if (nameA > nameB) return 1;
        if (nameA < nameB) return -1;
        // names must be equal
        return 0;
      });
      this.isLoading = false;
    }, error => {
      // todo: show error
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }
}
