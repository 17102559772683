export class ApiUrl {
  public static readonly DOMAIN_DEV = 'http://localhost:50020/v1/';
  public static readonly DOMAIN_TEST = 'https://api.smartpager.dev/dashboard/v1/';
  public static readonly DOMAIN_PROD = 'https://api.smartpager.at/dashboard/v1/';

  public static readonly PRIVACY__DELETION = 'privacy/deletion';

  public static readonly SIGN__IN = 'sign/in';
  public static readonly SIGN__OUT = 'sign/out';

  public static readonly USER = 'user';

  public static readonly LICENSE = 'license';
  public static readonly PLAN__USAGE = 'plan/usage';
  public static readonly PLAN__PRICING = 'plan/pricing';
  public static readonly PLAN__AVAILABLE = 'plan/available';
  public static readonly LICENSE__CHECKOUT__PURCHASE = 'license/checkout/purchase';
  public static readonly LICENSE__CHECKOUT__PREVIEW = 'license/checkout/preview';
  public static readonly LICENSE__CHANGE__PREVIEW = 'license/change/preview';
  public static readonly LICENSE__CANCEL = 'license/cancel';
  public static readonly LICENSE__SCHEDULE = 'license/schedule';
  public static readonly LICENSE__RENEW = 'license/renew';
  public static readonly LICENSE__UPDATE = 'license/update';
  public static readonly LICENSE__MEMBER_USAGE = 'license/member-usage';

  public static readonly PAYMENT_METHOD = 'payment-method';
  public static readonly PAYMENT_METHODS__SETUP_INTENT__CREATE = 'payment-methods/setup-intent/create';
  public static readonly PAYMENT_METHOD__UPDATE__DEFAULT = 'payment-method/update/default';
  public static readonly INVOICE_ADDRESS__UPDATE = 'invoice-address/update';

  public static readonly ADD_ONS__AVAILABLE = 'add-ons/available';
  public static readonly ADD_ON__SMS = 'add-on/sms';
  public static readonly ADD_ON__SMS__ENABLE = 'add-on/sms/enable';
  public static readonly ADD_ON__SMS__DISABLE = 'add-on/sms/disable';
  public static readonly ADD_ON__SMS__HISTORY = 'add-on/sms/history';
  public static readonly ADD_ON__SMS__PRICING = 'add-on/sms/pricing';
  public static readonly ADD_ON__SMS__PRICING__PREVIEW = 'add-on/sms/pricing/preview';
  public static readonly INVOICES = 'invoices';
  public static readonly UPCOMING_INVOICE = 'upcoming-invoice';

  public static readonly ACTION_NOTIFICATION = 'action-notification';
  public static readonly INTEGRATION__EXTERNAL_ICALENDAR__UPDATE = 'integration/external-icalendar/{id}/update';
  public static readonly OPERATION__ID__PROTOCOL__MESSAGE = 'operation/{id}/protocol/message';

  public static readonly BINDINGS = 'bindings';
  public static readonly BINDINGS__LWZOOE__INFO = 'bindings/lwzooe/info';
  public static readonly BINDINGS__LWZOOE__ENABLE = 'bindings/lwzooe/enable';
  public static readonly BINDINGS__LWZOOE__DISABLE = 'bindings/lwzooe/disable';
  public static readonly BINDINGS__CONNECT__INFO = 'bindings/connect/info';
  public static readonly BINDINGS__CONNECT__INFO__SETTINGS__UPDATE = 'bindings/connect/info/settings/update';
  public static readonly BINDINGS__CONNECT__ENABLE = 'bindings/connect/enable';
  public static readonly BINDINGS__CONNECT__DISABLE = 'bindings/connect/disable';
  public static readonly BINDINGS__CONNECT__PRINT__TEST_PAGE = 'bindings/connect/print/test-page';
  public static readonly BINDINGS__LS_TIROL__INFO = 'bindings/ls-tirol/info';
  public static readonly BINDINGS__LS_TIROL__ENABLE = 'bindings/ls-tirol/enable';
  public static readonly BINDINGS__LS_TIROL__DISABLE = 'bindings/ls-tirol/disable';
  public static readonly BINDINGS__LS_TIROL__ALARM_GROUPS__ID = 'bindings/ls-tirol/alarm-groups/{id}';
  public static readonly BINDINGS__WASSERKARTE__INFO = 'bindings/wasserkarte/info';
  public static readonly BINDINGS__WASSERKARTE__ENABLE = 'bindings/wasserkarte/enable';
  public static readonly BINDINGS__WASSERKARTE__DISABLE = 'bindings/wasserkarte/disable';
  public static readonly BINDINGS__RESCUE_TABLET__INFO = 'bindings/rescue-tablet/info';
  public static readonly BINDINGS__RESCUE_TABLET__ENABLE = 'bindings/rescue-tablet/enable';
  public static readonly BINDINGS__RESCUE_TABLET__DISABLE = 'bindings/rescue-tablet/disable';
  public static readonly BINDINGS__MAPTRIP__INFO = 'bindings/maptrip/info';
  public static readonly BINDINGS__MAPTRIP__ENABLE = 'bindings/maptrip/enable';
  public static readonly BINDINGS__MAPTRIP__DISABLE = 'bindings/maptrip/disable';
  public static readonly BINDINGS__EXTERNAL_ICALENDARS = 'bindings/external-icalendars';
  public static readonly BINDINGS__EXTERNAL_ICALENDARS__ADD = 'bindings/external-icalendars/add';
  public static readonly BINDINGS__EXTERNAL_ICALENDARS__DELETE = 'bindings/external-icalendars/delete';
  public static readonly BINDINGS__LWZ_OOE__INFO = 'bindings/lwz-ooe/info';
  public static readonly BINDINGS__LWZ_OOE__REQUEST = 'bindings/lwz-ooe/request';

  public static readonly ORGANIZATION_TYPES = 'organization-types';
  public static readonly COUNTRIES = 'countries';

  public static readonly ORGANIZATION__INFORMATION = 'organization/information';
  public static readonly ORGANIZATION__INFORMATION__UPDATE = 'organization/information/update';
  public static readonly ORGANIZATION__TYPE__UPDATE = 'organization/type/update';
  public static readonly ORGANIZATION__OPERATION_CATEGORIES = 'organization/{organizationId}/operation-categories';
  public static readonly ORGANIZATIONS__GROUPS = 'organizations/{organizationId}/groups';
  public static readonly ORGANIZATIONS__COMPETENCES = 'organizations/{organizationId}/competences';
  public static readonly ORGANIZATIONS__COMPETENCES__ID = 'organizations/{organizationId}/competences/{competenceId}';

  public static readonly INTERFACE__ACTIVE = 'interface/active';
  public static readonly INTERFACE__CREATE = 'interface/create';
  public static readonly INTERFACE__DELETE = 'interface/delete';

  public static readonly OPERATION__LIST = 'operation/list';
  public static readonly OPERATION__DETAIL = 'operation/detail';
  public static readonly OPERATION__CREATE = 'operation/create';
  public static readonly OPERATION__ID__END = 'operation/{id}/end';
  public static readonly OPERATION__WATER_SOURCES = 'operation/water-sources';
  public static readonly OPERATION__ID__REPORT = 'operation/{operationId}/report';

  public static readonly WASSERKARTE__FEATURES = 'wasserkarte/features';
  public static readonly WASSERKARTE__FEATURES__ID__GEOJSON = 'wasserkarte/features/{id}/geojson';

  public static readonly SETTINGS__PERMISSIONS = 'settings/permissions';
  public static readonly SETTINGS__ROLES = 'settings/roles';

  public static readonly MEMBER__LIST = 'member/list';
  public static readonly MEMBER__ADD__SIGN_UP = 'member/add/sign-up';
  public static readonly MEMBER__ADD__EMAIL_INVITATION = 'member/add/email-invitation';

  public static readonly EVENT__LIST = 'event/list';
  public static readonly EVENT__CREATE = 'event/create';
  public static readonly EVENT__REMINDER__SCHEDULE_TYPE = 'event/reminder/schedule-type'

  public static readonly MONITOR__LIST = 'monitor/list';
  public static readonly MONITOR__DETAILS = 'monitor/details';

  public static readonly NEWS__ROADMAP = 'news/roadmap';

  public static readonly PRIVACY__SETTING = 'privacy/setting';
}
