import {
  AfterViewInit,
  ApplicationRef,
  Component, ComponentFactoryResolver, ComponentRef,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ApiService} from "../../../../core/services/api.service";
import {OrganizationService} from "../../../../core/services/organization.service";
import {Operation} from "../../../../core/models/Operation";
import {ActivatedRoute, Router} from "@angular/router";
import {OperationDetailRequest} from "../../../../core/network/request/OperationDetailRequest";
import {OperationDetailResponse} from "../../../../core/network/response/OperationDetailResponse";
import * as L from "leaflet";
import {LayerGroup, MarkerOptions} from "leaflet";
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {OperationProtocolMessage} from "../../../../core/models/OperationProtocolMessage";
import {MatSnackBar} from "@angular/material/snack-bar";
import {WasserkarteFeatureModel} from "../../../../core/models/WasserkarteFeatureModel";
import {FeatureCollection} from "geojson";
import {WaterSource} from "../../../../core/network/models/WaterSource";
import {
  WasserkarteMapMarkerComponent
} from "../../../../components/markers/wasserkarte-map-marker/wasserkarte-map-marker.component";
import {WasserkarteSourceTypeModel} from "../../../../core/models/WasserkarteSourceTypeModel";
import {environment} from "../../../../../environments/environment";

/*import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";*/


@Component({
  selector: 'app-operation-detail',
  templateUrl: './operation-detail.component.html',
  styleUrls: ['./operation-detail.component.scss']
})
export class OperationDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  isLoading = false;
  isLoadingOperation = false;
  isLoadingProtocol = false;
  isLoadingWasserkarteSources = false;
  isLoadingWasserkarteFeatures = false;
  isLoadingOperationReport = false;

  menuOperationOpen = true;
  menuProtocolOpen = true;
  menuResponsesOpen = false;
  menuWasserkarteOpen = false;

  id: string | null = null;
  operation: Operation | null = null;
  preparedAddress: string = '';

  private map: L.Map | null = null;

  private layerGroupOperationAddress: LayerGroup | null = null;

  @ViewChild('protocolList') private protocolList: ElementRef | null = null;
  protocolMessages: OperationProtocolMessage[] = [];

  private protocolMessageSyncInterval: number | null = null;

  isSendingProtocolMessage: boolean = false;
  newMessage: string = '';

  showEndOperationBtn = false;
  isEndingOperation = false;
  isOperationManager = false;
  showDownloadOperationReport = false;

  wasserkarteSourceTypes: WasserkarteSourceTypeModel[] = [];
  wasserkarteSources: WaterSource[] = [];
  wasserkarteFeatures: WasserkarteFeatureModel[] = [];

  wasserkarteFilterRanges = [0.1, 0.25, 0.5, 1.0, 2.5, 5.0, 10.0, 25.0, 50.0];
  wasserkarteFilterCounts = [5, 10, 15, 20, 30, 40, 50, 100];

  wasserkarteFilterRangeSelected = 0.5;
  wasserkarteFilterCountSelected = 20;

  constructor(private apiService: ApiService, private organizationService: OrganizationService,
              private route: ActivatedRoute, private router: Router, private toolbarService: ToolbarService,
              private _snackBar: MatSnackBar, private injector: Injector, private applicationRef: ApplicationRef,
              private resolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.id = paramMap.get('id');
      if ((this.id ?? '') != '') {
        this.loadData(this.id!!);
      }
    });

    this.toolbarService.setActions([{icon: "close", path: "/home/operations", info: "Ansicht schließen"}]);

    /*L.PM.setOptIn(true);
    this.map?.pm.addControls({
      position: 'topleft',
      drawCircleMarker: false,
      rotateMode: false,
    });*/
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetActions();

    if (this.protocolMessageSyncInterval) {
      clearInterval(this.protocolMessageSyncInterval);
    }
  }

  onDownloadOperationReportClick(event: MouseEvent): void {
    this.isLoadingOperationReport = true;

    let organizationId = `${this.organizationService.getSelectedOrganization()?.id}`;

    this.apiService.operationReport(organizationId, this.operation!.id, response => {
      const filename = 'Einsatzbericht.pdf';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response);
      link.download = filename;
      link.click();

      window.URL.revokeObjectURL(link.href); // Clean up

      /*const blob = new Blob([response], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      window.open(url);*/
      this.isLoadingOperationReport = false;
    }, error => {
      alert("Fehler beim Herunterladen des Einsatzberichts");
      this.isLoadingOperationReport = false;
    }, () => {
    });

  }

  onEndOperationBtnClick(event: any): void {
    if (confirm("Möchtest du diesen Einsatz wirklich beenden?")) {

      this.isEndingOperation = true;

      let organizationId = `${this.organizationService.getSelectedOrganization()!.id}`;
      let operationId = `${this.operation!.id}`

      this.apiService.operationEnd(organizationId, operationId, response => {
        this.router.navigateByUrl('/home/operations');
      }, error => {
        alert('Fehler beim Beenden des Einsatzes!');
        this.isEndingOperation = false;
      }, () => {
        this.isEndingOperation = false;
      })
    }
  }

  onWasserkarteFilterRangeChange(event: any) {
    this.loadWasserkarteSources();
  }

  onWasserkarteFilterCountChange(event: any) {
    this.loadWasserkarteSources();
  }

  onWasserkartePortalLinkClick(event: MouseEvent, url: string | null): void {
    if (!url) return;

    window.open(url, '_blank');
    event.stopPropagation();
  }

  private initMap(): void {
    // const center = this.mapService.getCenter();
    this.map = L.map('map', {
      center: [48, 13],
      zoom: 15, // this.mapService.getZoom(),
      attributionControl: true,
      zoomControl: false,
      dragging: true
    });

    /*L.control.zoom({
      position: 'topright'
    }).addTo(this.map);*/

    let tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap',
      detectRetina: false
    });
    tiles.addTo(this.map!);

    this.layerGroupOperationAddress = L.layerGroup().addTo(this.map);
    //this.layerGroupWaterSources = L.layerGroup().addTo(this.map);

    /*this.mapGroupSearchMarker = L.layerGroup().addTo(this.map);
    this.mapGroupOperationAreas = L.layerGroup().addTo(this.map);
    this.mapGroupAmbulanceDepartments = L.layerGroup().addTo(this.map);
    this.mapGroupPositions = L.layerGroup().addTo(this.map);
    this.mapGroupMapObjects = L.layerGroup().addTo(this.map);
    this.mapGroupSearchMarker.setZIndex(100);
    this.mapGroupResources = L.layerGroup().addTo(this.map);
    this.mapGroupResources.setZIndex(10);
    this.setMapType(this.mapType);
    this.map.on('moveend', (x: any) => {
      this.onCenterChanged();
    });
    this.map.on('zoomend', (x: any) => {
      this.onZoomChanged();
    })
    this.setCenter(center.lat, center.lng);
    this.setZoomLevel(this.mapService.getZoom());
    this.onCenterChanged();*/
  }

  private loadData(id: string): void {
    this.isLoading = true;
    this.isLoadingOperation = true;

    const request = {
      organization_id: this.organizationService.getSelectedOrganization()?.id ?? 0,
      operation_id: id
    } as OperationDetailRequest;

    this.apiService.operationDetail(request, (response: OperationDetailResponse) => {
      this.operation = response.item;
      this.preparedAddress = this.toLocationString('<br>') ?? '';

      this.map?.setView([this.operation?.address?.latitude ?? 0, this.operation?.address?.longitude ?? 0])
      this.setOperationAddressMarker()
      this.loadWasserkarteSources();
      this.loadWasserkarteFeatures();

      this.loadProtocolMessages(true, true);
      this.startProtocolMessageSyncInterval();

      let org = this.organizationService.getSelectedOrganization();
      this.showEndOperationBtn = (this.operation?.time_end ?? 0) == 0 && (org?.is_operation_manager ?? false);
      this.isOperationManager = org?.is_operation_manager ?? false;
      this.showDownloadOperationReport = environment.isDev || org!.id == 1 || org!.id == 2253;
    }, (error: string) => {
      if (error == "not-found") {
        this.router.navigateByUrl('/');
      }
      // todo: handle other errors
    }, () => {
      this.isLoading = false;
      this.isLoadingOperation = false;
    })
  }

  private setOperationAddressMarker() {
    if (this.operation?.address == null) return;

    let addressMarker = L.marker({
      lat: this.operation.address.latitude ?? 0,
      lng: this.operation.address.longitude ?? 0
    }, {
      draggable: false,
      /*icon: L.icon({
        iconUrl: 'assets/ic_my_location.png',
        iconSize: [40, 40]
      }),*/
      icon: L.icon({
        iconUrl: 'assets/destination-marker.png',
        iconSize: [40, 64],
        iconAnchor: [20, 64],
        popupAnchor: [0, -64]
      }),
      /*icon: new L.DivIcon({
        iconSize: [22, 22],
        iconAnchor: [11, 11],
        className: 'map-object-marker',
        html: `<img src="${url}">`
      }),*/
      zIndexOffset: 100
    });
    addressMarker.bindPopup(L.popup({closeButton: false}).setLatLng({
      lat: this.operation.address.latitude ?? 0,
      lng: this.operation.address.longitude ?? 0
    }).setContent(`<b>Einsatzort</b><br>${this.toLocationString(',')}`));
    addressMarker.addTo(this.layerGroupOperationAddress!);
  }

  private toLocationString(separator: string = "\n", justStreetAddress: boolean = false): string {
    if ((this.operation?.address?.firm ?? '') == ""
      && (this.operation?.address?.street ?? '') == ""
      && (this.operation?.address?.street_number ?? '') == ""
      && (this.operation?.address?.floor ?? '') == ""
      && (this.operation?.address?.door_number ?? '') == ""
      && (this.operation?.address?.postcode ?? '') == ""
      && (this.operation?.address?.city ?? '') == ""
      && (this.operation?.address?.country ?? '') == ""
      && (this.operation?.address?.free_text ?? '') == "") return ""

    let result = "";

    if (!justStreetAddress && (this.operation?.address?.firm ?? "") != "") result += this.operation?.address?.firm

    if ((this.operation?.address?.street ?? "") != "") {
      if (result != "") result += separator
      result += this.operation?.address?.street

      if ((this.operation?.address?.street_number ?? "") != "") {
        result += " " + this.operation?.address?.street_number

        if (!justStreetAddress && (this.operation?.address?.floor ?? "") != "") {
          result += ("/") + this.operation?.address?.floor
        }

        if (!justStreetAddress && (this.operation?.address?.door_number ?? "") != "") {
          result += ("/") + this.operation?.address?.door_number
        }
      }
    }

    if (result != "" && ((this.operation?.address?.postcode ?? "") != "" || (this.operation?.address?.city ?? "") != ""))
      result += separator

    result += (this.operation?.address?.postcode ?? "")

    if ((this.operation?.address?.city ?? "") != "") {
      if ((this.operation?.address?.postcode ?? "") != "") result += " "
      result += this.operation?.address?.city
    }

    if ((this.operation?.address?.country ?? "") != "") {
      if (result != "") result += separator
      result += this.operation?.address?.country
    }

    if ((this.operation?.address?.free_text ?? "") != "") {
      if (result != "") result += separator
      result += this.operation?.address?.free_text
    }

    return result;
  }

  private loadWasserkarteSources() {
    this.isLoadingWasserkarteSources = true;

    // Remove existing map items
    this.wasserkarteSourceTypes
      .filter(x => x.layer)
      .forEach(x => this.map?.removeLayer(x.layer!));

    this.apiService.operationWaterSources({
      organization_id: `${this.organizationService.selectedOrganizationId}`,
      operation_id: this.operation!!.id,
      count: this.wasserkarteFilterCountSelected,
      range: this.wasserkarteFilterRangeSelected
    }, response => {
      this.wasserkarteSourceTypes = response.sourceTypes.map(x => ({
        id: x.id,
        name: x.name,
        category: x.category,
        isActive: false,
        portalUrl: x.portalUrl
      } as WasserkarteSourceTypeModel));

      this.wasserkarteSources = response.waterSources;

      // Preselect all
      this.wasserkarteSourceTypes.forEach(x => {
        x.itemCount = this.wasserkarteSources.filter(y => y.sourceTypeId == x.id).length;
        this.onWasserkarteSourceTypeClick({} as MouseEvent, x.id);
      });

      /*this.wasserkarteSourceTypes = this.wasserkarteSourceTypes.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });*/
    }, error => {
      this.isLoadingWasserkarteSources = false;
    }, () => {
      this.isLoadingWasserkarteSources = false;
    });
  }

  getWasserkarteSourceTypeIconUrl(category: string | null): string | null {
    switch (category) {
      case 'hydrant':
        return 'assets/icons/fire_hydrant_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
      case 'building':
        return 'assets/icons/house_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
    }
    return 'assets/icons/water_drop_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
  }

  private createComponent(component: any, props: any): HTMLElement {
    const factory = this.resolver.resolveComponentFactory(component);
    const componentRef: ComponentRef<any> = factory.create(this.injector);

    Object.assign(componentRef.instance, props);

    this.applicationRef.attachView(componentRef.hostView);
    const div = document.createElement('div');
    div.appendChild(componentRef.location.nativeElement);

    return div;
  }

  private startProtocolMessageSyncInterval(): void {
    if (this.protocolMessageSyncInterval != null) {
      clearInterval(this.protocolMessageSyncInterval);
    }

    let interval = 5 * 1000; // every 5s

    this.protocolMessageSyncInterval = setInterval(() => {
      this.loadProtocolMessages();
    }, interval);
  }

  private loadProtocolMessages(scrollToBottom: boolean = false, isInitial: boolean = false): void {
    this.apiService.getOperationProtocolMessages(`${this.organizationService.selectedOrganizationId}`, this.operation!!.id, response => {
      let countChanged = this.protocolMessages.length != response.entries.length;
      this.protocolMessages = response.entries;
      if (scrollToBottom || countChanged) {
        setTimeout(() => {
          this.protocolList!.nativeElement!.scrollTop = this.protocolList?.nativeElement.scrollHeight;
        }, 0);
      }

      if (!isInitial && countChanged) {
        this._snackBar.open("Neuer Protokolleintrag", '');
      }
    }, error => {

    }, () => {
    })
  }

  private postProtocolMessage(message: string): void {
    if (this.isSendingProtocolMessage) return;

    this.isSendingProtocolMessage = true;

    this.apiService.postOperationProtocolMessages(`${this.organizationService.selectedOrganizationId}`, this.operation!!.id, message, () => {
      this.loadProtocolMessages(true);
      this.newMessage = '';
      this.isSendingProtocolMessage = false;
    }, error => {
      alert("Fehler beim Senden der Nachricht! Bitte versuche es später nochmal.")
      this.isSendingProtocolMessage = false;
    }, () => {
      this.isSendingProtocolMessage = false;
    })
  }

  onSendProtocolMessageClick(event: any): void {
    if (this.newMessage.trim() == "") return;

    this.postProtocolMessage(this.newMessage);
  }

  onProtocolMessageKeyDown(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      this.postProtocolMessage(this.newMessage);
    }
  }

  onZoomClick(event: MouseEvent, steps: number): void {
    this.map?.setZoom(this.map?.getZoom() + steps, {animate: true});
  }

  private loadWasserkarteFeatures(): void {
    this.isLoadingWasserkarteFeatures = true;

    let orgId = this.organizationService.getSelectedOrganization()!.id;
    this.apiService.wasserkarteFeatures(`${orgId}`, (features: WasserkarteFeatureModel[]) => {
      this.wasserkarteFeatures = features.sort((a, b) =>
        a.title > b.title ? 1 : -1
      );
    }, error => {
      console.error(error);
      this.isLoadingWasserkarteFeatures = false;
    }, () => {
      this.isLoadingWasserkarteFeatures = false;
    });
  }

  onWasserkarteSourceTypeClick(event: MouseEvent, sourceTypeId: number): void {
    let sourceType = this.wasserkarteSourceTypes.find(x => x.id == sourceTypeId);
    if (!sourceType) {
      // todo: log
      return;
    }

    if (sourceType.isActive) {
      // disable, remove layer
      if (sourceType.layer) {
        this.map?.removeLayer(sourceType.layer)
      }
      sourceType.isActive = false;
    } else {
      let layerGroup = new L.LayerGroup();

      this.wasserkarteSources
        .filter(x => x.sourceTypeId == sourceTypeId)
        .forEach(x => {
          const markerContainer = this.createComponent(WasserkarteMapMarkerComponent, {operationWaterSource: x});
          let addressMarker = L.marker({lat: x.lat, lng: x.lng}, {
            draggable: false,
            icon: L.icon({
              iconUrl: x.icon?.url ?? "",
              iconSize: [x.icon?.width ?? 0, x.icon?.height ?? 0],
              iconAnchor: [x.icon?.anchorX ?? 0, x.icon?.anchorY ?? 0],
              popupAnchor: [0, -(x.icon?.anchorY ?? 0)]
            }),
            zIndexOffset: 100
          });
          addressMarker.bindPopup(L.popup({closeButton: false}).setLatLng({
            lat: x.lat,
            lng: x.lng
          }).setContent(markerContainer));

          addressMarker.addTo(layerGroup);
        });
      sourceType.layer = layerGroup;

      if (this.map) sourceType.layer.addTo(this.map);

      sourceType.isActive = true;
    }
  }

  onWasserkarteFeatureClick(event: MouseEvent, featureId: string): void {
    let feature = this.wasserkarteFeatures.find(x => x.id == featureId);
    if (!feature) {
      // todo: log
      return;
    }

    if (feature.isLoading) {
      return;
    }

    if (feature.isActive) {
      // disable, remove layer
      if (feature.layer) {
        this.map?.removeLayer(feature.layer)
      }
      feature.isActive = false;
    } else {
      this.loadWasserkarteFeatureObjects(featureId);
      feature.isActive = true;
    }
  }

  private loadWasserkarteFeatureObjects(featureId: string): void {
    let orgId = this.organizationService.getSelectedOrganization()!.id;

    let customIcon = function (properties: any) {
      return L.icon({
        iconUrl: properties.customIcon.iconUrl,
        iconSize: properties.customIcon.iconSize,
        iconAnchor: properties.customIcon.iconAnchor
      });
    };

    let feature = this.wasserkarteFeatures.find(x => x.id == featureId);
    if (!feature) {
      // todo: log
      return;
    }

    feature.isLoading = true;

    this.apiService.wasserkarteFeatureGeoJson(`${orgId}`, featureId, (geoJson: string) => {
      feature!.geoJson = JSON.parse(geoJson) as FeatureCollection;
      feature!.layer = L.geoJSON(feature!.geoJson, {
        pointToLayer: function (x, latlng) {
          return L.marker(latlng, {
            //icon: customIcon(feature.properties),
            icon: customIcon(x.properties)

          } as MarkerOptions);
        }
      }).addTo(this.map!!);

      feature!.isLoading = false;
    }, error => {
      console.error(error);
    }, () => {
      feature!.isLoading = false;
    });
  }
}
