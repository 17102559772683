<main>
  <div class="body-content-centered">
    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <nav class="body-content-header-nav">
        <div class="body-content-header-nav-title">Mitglieder</div>
        <div class="body-content-header-nav-actions">
          <app-action-button iconKey="add" routerLink="/home/members/add" *ngIf="isUserManager">
            Mitglied hinzufügen
          </app-action-button>
        </div>
      </nav>

      <div id="list">
        <ul id="members">
          <li *ngFor="let member of members">
            <div class="profile"><img src="../assets/images/user-icon.webp"></div>
            <div class="text">
              <div class="title">{{ member.first_name }} {{ member.last_name }}</div>
              <div class="time">{{ member.email }}</div>
            </div>
            <div class="availability" [ngClass]="member.is_available ? 'available' : 'unavailable'">
              <mat-icon>{{ member.is_available ? 'check_mark' : 'close' }}</mat-icon>
            </div>
          </li>
        </ul>
        <aside *ngIf="isAdministrator && showCompetencesBtn">
          <ul>
            <!--<li>Gruppen</li>-->
            <li [routerLink]="'/home/members/competences'">Kompetenzen</li>
          </ul>
        </aside>
      </div>


      <div *ngIf="members.length <= 0">
        Keine Mitglieder vorhanden.
      </div>
    </ng-template>
  </div>
</main>
