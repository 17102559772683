<main>
  <menu id="menu-left">
    <li (click)="menuOperationOpen = !menuOperationOpen" [class.active]="menuOperationOpen">Details</li>
    <li (click)="menuProtocolOpen = !menuProtocolOpen" [class.active]="menuProtocolOpen">Protokoll (Beta)</li>
  </menu>

  <div id="content-left" class="content" *ngIf="menuOperationOpen || menuProtocolOpen">
    <ul>
      <li id="content-operation" *ngIf="menuOperationOpen">
        <div class="content-header" (click)="menuOperationOpen = false">
          <span>Details</span>
          <mat-spinner [diameter]="20" mode="indeterminate" color="accent" *ngIf="isLoadingOperation"></mat-spinner>
        </div>
        <div class="content-body">
          <h1 [style.border-color]="operation?.category_color ?? '#ddd'">{{ operation?.title }}</h1>
          <div id="time">{{ (operation?.time_start ?? 0) * 1000 | date: 'dd.MM.yyyy HH:mm' }} - <span
            *ngIf="(operation?.time_end ?? 0) > 0">{{ (operation?.time_end ?? 0) * 1000 | date: 'dd.MM.yyyy HH:mm' }}</span>
          </div>
          <div id="text" *ngIf="(operation?.text ?? '') != ''">{{ operation?.text }}</div>
          <section id="address">
            <h2>Adresse</h2>
            <div [innerHTML]="preparedAddress"></div>
          </section>
          <section id="answers">
            <h2>Einsatzkräfte ({{ (operation?.answers?.length ?? 0) }})</h2>
            <div id="answers-empty" *ngIf="(operation?.answers?.length ?? 0) <= 0">Noch keine Zusagen.</div>
            <ul>
              <li *ngFor="let a of operation?.answers">{{ a.first_name }} {{ a.last_name }}</li>
            </ul>
          </section>
          <section id="actions">
            <button *ngIf="showDownloadOperationReport && isOperationManager" (click)="onDownloadOperationReportClick($event)"
                    [disabled]="isLoadingOperationReport || isEndingOperation">
              Einsatzbericht herunterladen (Beta)
            </button>

            <button *ngIf="showEndOperationBtn" (click)="onEndOperationBtnClick($event)" [disabled]="isEndingOperation">
              Einsatz beenden
            </button>
          </section>
        </div>
      </li>
      <li id="content-protocol" *ngIf="menuProtocolOpen">
        <div class="content-header" (click)="menuProtocolOpen = false">
          <span>Protokoll (Beta)</span>
          <mat-spinner [diameter]="20" mode="indeterminate" color="accent" *ngIf="isLoadingProtocol"></mat-spinner>
        </div>
        <div class="content-body">
          <ul #protocolList>
            <li *ngFor="let pm of protocolMessages" [class.myself]="!pm.user">
              <div class="protocol-user-icon" *ngIf="pm.user"><img src="/assets/images/user-icon.webp"></div>
              <div class="protocol-message">
                <div class="protocol-message-user" *ngIf="pm.user">{{ pm.user.first_name }} {{ pm.user.last_name }}
                </div>
                <div class="protocol-message-text">{{ pm.message }}</div>
                <div class="protocol-message-time">{{ pm.time * 1000 | date: 'dd.MM.yyyy HH:mm' }}</div>
              </div>
            </li>
          </ul>
          <div id="protocol-input">
            <input type="text" placeholder="Nachricht schreiben" [(ngModel)]="newMessage"
                   (keydown)="onProtocolMessageKeyDown($event)" [disabled]="isSendingProtocolMessage">
            <mat-icon (click)="onSendProtocolMessageClick($event)">send</mat-icon>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div id="map"></div>

  <nav id="nav-center-bottom">
    <mat-icon class="material-icons-outlined" (click)="onZoomClick($event, 1)">add</mat-icon>
    <mat-icon class="material-icons-outlined" (click)="onZoomClick($event, -1)">remove</mat-icon>
  </nav>

  <!--<div id="menu">
    <div class="loading" *ngIf="isLoading || operation == null; else menuContent">Lade Daten</div>
    <ng-template #menuContent>
      <div id="category-indicator" *ngIf="operation?.category_color != null"
           [style.background-color]="operation?.category_color ?? '#ddd'"></div>
      <h1>{{ operation?.title }}</h1>
      <div id="time">{{ (operation?.time_start ?? 0) * 1000 | date: 'dd.MM.yyyy HH:mm' }} - <span
        *ngIf="(operation?.time_end ?? 0) > 0">{{ (operation?.time_end ?? 0) * 1000 | date: 'dd.MM.yyyy HH:mm' }}</span>
      </div>
      <div id="text" *ngIf="(operation?.text ?? '') != ''">{{ operation?.text }}</div>
      <section id="address">
        <h2>Adresse</h2>
        <div [innerHTML]="preparedAddress"></div>
      </section>
      <section id="answers">
        <h2>Einsatzkräfte ({{ (operation?.answers?.length ?? 0) }})</h2>
        <div id="answers-empty" *ngIf="(operation?.answers?.length ?? 0) <= 0">Noch keine Zusagen.</div>
        <ul>
          <li *ngFor="let a of operation?.answers">{{ a.first_name }} {{ a.last_name }}</li>
        </ul>
      </section>
      <section id="actions">
        <button *ngIf="showEndOperationBtn" (click)="onEndOperationBtnClick($event)" [disabled]="isEndingOperation">
          Einsatz beenden
        </button>
      </section>
    </ng-template>
  </div>-->

  <div id="content-right" class="content" *ngIf="menuResponsesOpen || menuWasserkarteOpen">
    <ul>
      <li id="content-responses" *ngIf="menuResponsesOpen">
        <div class="content-header" (click)="menuResponsesOpen = false">Rückmeldungen</div>
        <div class="content-body">
          Responses
        </div>
      </li>
      <li id="content-wasserkarte" *ngIf="menuWasserkarteOpen">
        <div class="content-header" (click)="menuWasserkarteOpen = false">
          <span>Wasserkarte</span>
          <mat-spinner [diameter]="20" mode="indeterminate" color="accent"
                       *ngIf="isLoadingWasserkarteSources || isLoadingWasserkarteFeatures"></mat-spinner>
        </div>
        <div class="content-body">
          <div id="content-wasserkarte-filter">
            <mat-form-field appearance="fill">
              <mat-label>Umkreis</mat-label>
              <mat-select [(ngModel)]="wasserkarteFilterRangeSelected"
                          (ngModelChange)="onWasserkarteFilterRangeChange($event)">
                <mat-option *ngFor="let wfr of wasserkarteFilterRanges" [value]="wfr">{{ wfr }} km</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Anzahl</mat-label>
              <mat-select [(ngModel)]="wasserkarteFilterCountSelected"
                          (ngModelChange)="onWasserkarteFilterCountChange($event)">
                <mat-option *ngFor="let wfc of wasserkarteFilterCounts" [value]="wfc">{{ wfc }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <ul>
            <li *ngFor="let type of wasserkarteSourceTypes"
                (click)="onWasserkarteSourceTypeClick($event, type.id)"
                [class.active]="type.isActive">
              <img [src]="getWasserkarteSourceTypeIconUrl(type.category)">
              <span>{{ type.name }} ({{ type.itemCount }})</span>
              <img src="assets/icons/open_in_new_24dp_007AFF_FILL0_wght400_GRAD0_opsz24.svg" *ngIf="type.portalUrl"
                   class="content-wasserkarte-portal-link"
                   (click)="onWasserkartePortalLinkClick($event, type.portalUrl)" title="In wasserkarte.info öffnen">
            </li>
          </ul>
          <ul>
            <li *ngFor="let feature of wasserkarteFeatures"
                (click)="onWasserkarteFeatureClick($event, feature.id)"
                [class.active]="feature.isActive">
              <img src="assets/icons/layers_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg">
              <span>{{ feature.title }}</span>
              <img src="assets/icons/open_in_new_24dp_007AFF_FILL0_wght400_GRAD0_opsz24.svg"
                   *ngIf="feature.portalUrl && !feature.isLoading" class="content-wasserkarte-portal-link"
                   (click)="onWasserkartePortalLinkClick($event, feature.portalUrl)" title="In wasserkarte.info öffnen">
              <mat-spinner [diameter]="20" mode="indeterminate" color="accent" *ngIf="feature.isLoading"></mat-spinner>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>

  <menu id="menu-right">
    <!--<li (click)="menuResponsesOpen = !menuResponsesOpen" [class.active]="menuResponsesOpen">Rückmeldungen</li>-->
    <!--<li>Ressourcen</li>-->
    <li (click)="menuWasserkarteOpen = !menuWasserkarteOpen" [class.active]="menuWasserkarteOpen">Wasserkarte</li>
  </menu>
</main>
