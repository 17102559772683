<main>
  <div class="body-content-centered">
    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <nav class="body-content-header-nav">
        <div class="body-content-header-nav-title">Kompetenzen (Beta)</div>
        <div class="body-content-header-nav-actions">
          <app-action-button iconKey="add" *ngIf="isUserManager" (click)="onCreateCompetenceClick($event)">
            Neue Kompetenz
          </app-action-button>
        </div>
      </nav>

      <div id="list">
        <ul id="items">
          <li *ngFor="let item of competences" (click)="onCompetenceClick($event, item)">
            <div class="item-short-name"
                 [style.background-color]="item.color ?? ''"
                 [style.color]="textColorBasedOnBackground(item.color ?? '#ffffff')">
              {{ item.shortName ?? item.name }}
            </div>
            <div class="item-name">{{ item.name }}</div>
          </li>
        </ul>
      </div>

      <div *ngIf="competences.length <= 0">
        Keine Kompetenzen vorhanden.
      </div>
    </ng-template>
  </div>
</main>
